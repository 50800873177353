import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["url", "copy_message"]

  copy() {
    this.urlTarget.select()
    if (document.execCommand("copy")) {
      this.copy_messageTarget.innerText = "copied."
      this.copy_messageTarget.className = 'text-success'
    }
  }
}
